// To replace with tailwind's Select if you need multiple selection

import { ChevronDown } from "lucide-react";
import React from "react";
import {
  Select as AriaSelect,
  SelectProps as AriaSelectProps,
  Button,
  ListBox,
  ListBoxItemProps,
  SelectValue,
  ValidationResult,
  composeRenderProps,
} from "react-aria-components";
import { tv } from "tailwind-variants";
import { Description, FieldError, Label } from "./Field";
import { DropdownItem, DropdownSection, DropdownSectionProps } from "./ListBox";
import { Popover } from "./Popover";
import { composeTailwindRenderProps, focusRing } from "./utils";

const styles = tv({
  extend: focusRing,
  base: "flex items-center text-start gap-4 w-full cursor-default shadow-[inset_0_1px_0_0_rgba(255,255,255,0.1)] min-w-[150px] transition",
  variants: {
    variant: {
      primary:
        "border border-secondary-main-20 rounded-sm pl-3 pr-2 py-2 text-secondary-main-100 bg-primary-main-100",
      information:
        "text-information-main-1 bg-information-main-10 py-1 px-1.5 gap-2 text-xs min-h-[24px] rounded",
    },
    isDisabled: {
      false:
        "hover:bg-gray-100 pressed:bg-gray-200 group-invalid:border-error-main-100 group-invalid:focus:border-error-main-500 group-invalid:bg-error-main-25 group-invalid:text-error-main-500 forced-colors:group-invalid:border-[Mark]",
      true: "opacity-50 bg-sidebar-gray-hover forced-colors:text-[GrayText] forced-colors:border-[GrayText]",
    },
  },
  defaultVariants: { variant: "primary" },
});

const dropdownIconStyles = tv({
  base: "w-4 h-4 forced-colors:text-[ButtonText] group-disabled:text-sidebar-gray-hover forced-colors:group-disabled:text-[GrayText]",
  variants: {
    variant: {
      primary: "text-secondary-main-100",
      information: "text-information-main-1",
    },
  },
  defaultVariants: { variant: "primary" },
});

export interface SelectProps<T extends object>
  extends Omit<AriaSelectProps<T>, "children"> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  items?: Iterable<T>;
  children: React.ReactNode | ((item: T) => React.ReactNode);
  variant?: "primary" | "information";
  startIcon?: JSX.Element;
  multiple?: boolean;
}

/**
 * React-aria-components Select implementation with application styles (based on https://react-spectrum.adobe.com/react-aria-tailwind-starter/index.html?path=/docs/select--docs)
 * @param props containing
 * label: label to associate with the input
 * description: description message to add more informations to user
 * errorMessage: eventual error message to show at the bottom of the component
 * children: items that should be displayed in the ListBox component
 * items: the options to populate the ListBox with
 * variant: tailwind variant to style the component (possible values: "primary" | "information")
 * startIcon: potential icon to display at the beginning (left) of the input
 */
export default function Select<T extends object>({
  label,
  description,
  errorMessage,
  children,
  items,
  variant,
  startIcon: Icon,
  ...props
}: SelectProps<T>) {
  return (
    <AriaSelect
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        "group flex flex-col gap-2"
      )}
    >
      {label && <Label variant={variant}>{label}</Label>}
      <Button
        className={composeRenderProps("", (className, renderProps) =>
          styles({
            ...renderProps,
            variant,
            className,
          })
        )}
      >
        {Icon ?? null}
        <SelectValue className="flex-1 text-base placeholder-shown:italic" />
        <ChevronDown aria-hidden className={dropdownIconStyles({ variant })} />
      </Button>
      {description && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
      <Popover className="min-w-[--trigger-width]" variant={variant}>
        <ListBox
          items={items}
          className="outline-none p-1 max-h-[inherit] overflow-auto [clip-path:inset(0_0_0_0_round_.75rem)]"
        >
          {children}
        </ListBox>
      </Popover>
    </AriaSelect>
  );
}

export interface SelectItemProps extends ListBoxItemProps {
  variant?: "primary" | "information";
}

/**
 * React-aria-components SelectItem implementation with application styles
 * used in Select component
 */
export function SelectItem(props: SelectItemProps) {
  return <DropdownItem {...props} />;
}

/**
 * React-aria-components SelectSection implementation with application styles
 * used in Select component
 */
export function SelectSection<T extends object>(
  props: DropdownSectionProps<T>
) {
  return <DropdownSection {...props} />;
}
